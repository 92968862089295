import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { Switch, Button, message, Spin } from "antd";
import { role as roleApi } from "../../apis";
import { SaveOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import MyTable from "../../components/MyTable";
import styled from "styled-components";

export default () => {
  const myTableRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const { isMobile, permissions } = useSelector(state => state.general);
  const [data, setFormData] = React.useState([]);

  const onChange = (value) => {
    setFormData(state => ([...state.filter(p => !(p.code === value.code && p.role === value.role)), value]));
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      await roleApi.update(data);

      message.success("Таны хүсэлт амжилттай!");
    } catch (err) {
      message.error(err?.message || "Таны хүсэлт амжилтгүй!");
    }

    setLoading(false);
  };

  const loadData = async () => {
    let res = await roleApi.get();

    setFormData(res);
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const columns = useHeader({ permissions: data, onChange });

  return (
    <PageContainer>
      <PageHeader title="Хандах эрх засварлах" extra={[<Button icon={<SaveOutlined />} type="primary" shape={isMobile&&"circle"} loading={loading} onClick={() => { onSubmit(); }}>{!isMobile && "Хадгалах"}</Button> ]}/>
      <PageContent style={{ paddingBottom: "40px" }}>
        <Spin spinning={loading} >
          <Table
            scroll={{ x: "100px" } }
            bordered={false}
            ref={myTableRef}
            pagination={false}
            columns={columns}
            dataSource={permissions}
            rowKey={(record) => record?.id} />
        </Spin>
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ permissions, onChange }) => {
  return [{
    title : "Нэр",
    render: (record) => {
      if (record?.type === "GROUP") {
        return (<div style={{ fontWeight: "bold" }}>{record.name}</div>);
      }

      return (<div >{record?.name}</div>);
    }
  }, {
    title : <Title>Супер админ</Title>,
    render: (record) => {
      if (record.type === "GROUP") return;

      return (
        <Permission>
          <Switch checked={permissions.find(p => p.code === record.code && p.role === "SUPER" && p.is_accept === true)}
            onChange={(e) => onChange({
              code     : record.code,
              role     : "SUPER",
              is_accept: e
            })} />
        </Permission>
      );
    }
  }, {
    title : <Title>Санхүү</Title>,
    render: (record) => {
      if (record.type === "GROUP") return;

      return (
        <Permission>
          <Switch checked={permissions.find(p => p.code === record.code && p.role === "FINANCE" && p.is_accept === true)}
            onChange={(e) => onChange({
              code     : record.code,
              role     : "FINANCE",
              is_accept: e
            })} />
        </Permission>
      );
    }
  }, {
    title : <Title>Менежер</Title>,
    render: (record) => {
      if (record.type === "GROUP") return;

      return (
        <Permission>
          <Switch checked={permissions.find(p => p.code === record.code && p.role === "MANAGER" && p.is_accept === true)}
            onChange={(e) => onChange({
              code     : record.code,
              role     : "MANAGER",
              is_accept: e
            })} />
        </Permission>
      );
    }
  }, {
    title : <Title style={{ textAlign: "center" }}>Ажилтан</Title>,
    render: (record) => {
      if (record.type === "GROUP") return;

      return (
        <Permission>
          <Switch checked={permissions.find(p => p.code === record.code && p.role === "STAFF" && p.is_accept === true)}
            onChange={(e) => onChange({
              code     : record.code,
              role     : "STAFF",
              is_accept: e
            })} />
        </Permission>
      );
    }
  }];
};

const Table = styled(MyTable)`
  .ant-table-thead > tr > th {    
    white-space: nowrap !important;
    padding: 0 !important;
    width: 220px; 
    
  .text-center {
    text-align: center;
  }

   &:first-child { 
    width: auto;
    min-width: 220px;
    text-align: center;
   }
   &:last-child {
     text-align: center;
   }
  }
  .ant-table-cell {  
   &:last-child { 
    width: 220px;  
    text-align: center;
   } 
  }
`;

const Title = styled.div`
  text-align: center;
  min-width: 220px;
  max-width: 220px;
  padding: 4px 8px;
`;


const Permission = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .ant-checkbox-wrapper {
    margin-right: 10px;
  }
  .ant-select {
    width: 160px;
  }
`;