import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { SettingOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd";
import { CustomTable, RowAction } from "../../components";
import { useHistory, Link } from "react-router-dom";
import { feeSettings as feeSettingsApi } from "../../apis";
import { useSelector } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import { tugrug } from "../../utils";

export default () => {
  const myTableRef = React.useRef();
  const { isMobile } = useSelector(state => state.general);
  const [query, setQuery] = React.useState("");
  const history = useHistory();
  const [filters, setFilters] = React.useState({
    query : "",
    offset: {
      page : 1,
      limit: 10
    }
  });

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query) {
      timeout = setTimeout(() => {
        setFilters(state => ({ ...state, query: query }));
      }, 300);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  const onClear = () => {
    setQuery(null);
    setFilters({
      filter: {
        query: ""
      },
      offset: {
        page : 1,
        limit: 10
      },
      start_date: undefined,
      end_date  : undefined
    });
  };

  const renderFee = (fee) => {
    return (
      <>
        <td className="ant-table-cell">
          {fee.acquirer_fee_type || "-"}
        </td>
        <td className="ant-table-cell">
          {fee.acquirer_fee_type === "FLAT" ? tugrug(fee.acquirer_fee) : `${fee.acquirer_fee}%`}
        </td>
        <td className="ant-table-cell">
          {tugrug(fee.acquirer_min_amount)}
        </td>
        <td className="ant-table-cell">
          {tugrug(fee.acquirer_max_amount)}
        </td>
        <td className="ant-table-cell">
          {fee.issuer_fee_type || "-"}
        </td>
        <td className="ant-table-cell">
          {fee.issuer_fee_type === "FLAT" ? tugrug(fee.issuer_fee) : `${fee.issuer_fee}%`}
        </td>
        <td className="ant-table-cell">
          {tugrug(fee.issuer_min_amount)}
        </td>
        <td className="ant-table-cell">
          {tugrug(fee.issuer_max_amount)}
        </td>
        <td className="ant-table-cell">
          {fee.bom_fee_type || "-"}
        </td>
        <td className="ant-table-cell">
          {fee.bom_fee_type === "FLAT" ? tugrug(fee.bom_fee) : `${fee.bom_fee}%`}
        </td>
        <td className="ant-table-cell">
          {tugrug(fee.bom_min_amount)}
        </td>
        <td className="ant-table-cell">
          {tugrug(fee.bom_max_amount)}
        </td>
        <td className="ant-table-cell">
          {fee.epay_fee_type || "-"}
        </td>
        <td className="ant-table-cell">
          {fee.epay_fee_type === "FLAT" ? tugrug(fee.epay_fee) : `${fee.epay_fee}%`}
        </td>
        <td className="ant-table-cell">
          {tugrug(fee.epay_min_amount)}
        </td>
        <td className="ant-table-cell">
          {tugrug(fee.epay_max_amount)}
        </td>
      </>
    );
  };

  const renderFeeByInterval = (intervals, idx) => {
    const cells = [];
    cells.push([0, 1, 2, 3].map((index) => (
      idx === 2 ? <>
        <td className="ant-table-cell border-bottom">
          {intervals[index].acquirer_fee_type || "-"}
        </td>
        <td className="ant-table-cell border-bottom">
          {intervals[index].acquirer_fee_type === "FLAT" ? tugrug(intervals[index].acquirer_fee) : `${intervals[index].acquirer_fee}%`}
        </td>
        <td className="ant-table-cell border-bottom">
          {tugrug(intervals[index].acquirer_min_amount)}
        </td>
        <td className="ant-table-cell border-last border-bottom">
          {tugrug(intervals[index].acquirer_max_amount)}
        </td>
      </>
        :
        <>
          <td className="ant-table-cell">
            {intervals[index].acquirer_fee_type || "-"}
          </td>
          <td className="ant-table-cell">
            {intervals[index].acquirer_fee_type === "FLAT" ? tugrug(intervals[index].acquirer_fee) : `${intervals[index].acquirer_fee}%`}
          </td>
          <td className="ant-table-cell">
            {tugrug(intervals[index].acquirer_min_amount)}
          </td>
          <td className="ant-table-cell border-last">
            {tugrug(intervals[index].acquirer_max_amount)}
          </td>
        </>
    )));
    cells.push([0, 1, 2, 3].map((index) => (
      idx === 2 ? <>
        <td className="ant-table-cell border-bottom">
          {intervals[index].issuer_fee_type || "-"}
        </td>
        <td className="ant-table-cell border-bottom">
          {intervals[index].issuer_fee_type === "FLAT" ? tugrug(intervals[index].issuer_fee) : `${intervals[index].issuer_fee}%`}
        </td>
        <td className="ant-table-cell border-bottom">
          {tugrug(intervals[index].issuer_min_amount)}
        </td>
        <td className="ant-table-cell border-last border-bottom">
          {tugrug(intervals[index].issuer_max_amount)}
        </td>
      </>
        :
        <>
          <td className="ant-table-cell">
            {intervals[index].issuer_fee_type || "-"}
          </td>
          <td className="ant-table-cell">
            {intervals[index].issuer_fee_type === "FLAT" ? tugrug(intervals[index].issuer_fee) : `${intervals[index].issuer_fee}%`}
          </td>
          <td className="ant-table-cell">
            {tugrug(intervals[index].issuer_min_amount)}
          </td>
          <td className="ant-table-cell border-last">
            {tugrug(intervals[index].issuer_max_amount)}
          </td>
        </>
    )));
    cells.push([0, 1, 2, 3].map((index) => (
      idx === 2 ? <>
        <td className="ant-table-cell border-bottom">
          {intervals[index].bom_fee_type || "-"}
        </td>
        <td className="ant-table-cell border-bottom">
          {intervals[index].bom_fee_type === "FLAT" ? tugrug(intervals[index].bom_fee) : `${intervals[index].bom_fee}%`}
        </td>
        <td className="ant-table-cell border-bottom">
          {tugrug(intervals[index].bom_min_amount)}
        </td>
        <td className="ant-table-cell border-last border-bottom">
          {tugrug(intervals[index].bom_max_amount)}
        </td>
      </>
        :
        <>
          <td className="ant-table-cell">
            {intervals[index].bom_fee_type || "-"}
          </td>
          <td className="ant-table-cell">
            {intervals[index].bom_fee_type === "FLAT" ? tugrug(intervals[index].bom_fee) : `${intervals[index].bom_fee}%`}
          </td>
          <td className="ant-table-cell">
            {tugrug(intervals[index].bom_min_amount)}
          </td>
          <td className="ant-table-cell border-last">
            {tugrug(intervals[index].bom_max_amount)}
          </td>
        </>
    )));
    cells.push([0, 1, 2, 3].map((index) => (
      idx === 2 ? <>
        <td className="ant-table-cell border-bottom">
          {intervals[index].epay_fee_type || "-"}
        </td>
        <td className="ant-table-cell border-bottom">
          {intervals[index].epay_fee_type === "FLAT" ? tugrug(intervals[index].epay_fee) : `${intervals[index].epay_fee}%`}
        </td>
        <td className="ant-table-cell border-bottom">
          {tugrug(intervals[index].epay_min_amount)}
        </td>
        <td className="ant-table-cell border-last border-bottom">
          {tugrug(intervals[index].epay_max_amount)}
        </td>
      </>
        :
        <>
          <td className="ant-table-cell">
            {intervals[index].epay_fee_type || "-"}
          </td>
          <td className="ant-table-cell">
            {intervals[index].epay_fee_type === "FLAT" ? tugrug(intervals[index].epay_fee) : `${intervals[index].epay_fee}%`}
          </td>
          <td className="ant-table-cell">
            {tugrug(intervals[index].epay_min_amount)}
          </td>
          <td className="ant-table-cell border-last">
            {tugrug(intervals[index].epay_max_amount)}
          </td>
        </>
    )));
    return <>{cells}</>;
  };

  return (
    <PageContainer>
      <PageHeader title="Шимтгэл тохиргоо" />
      <PageContent>
        <InoutTable
          pagination={true}
          size="small"
          ref={myTableRef}
          filters={filters}
          loadData={feeSettingsApi.list}
          rowKey={(record) => record.id}
          thead={() => (
            <thead className="ant-table-thead">
              <tr className="sticky-header">
                <th className="ant-table-cell" rowSpan={3}>№</th>
                <th className="ant-table-cell sticky-column-head" rowSpan={3}>Харилцагч</th>
                <th className="ant-table-cell sticky-column-2-1 border-last" rowSpan={3} colSpan={2}>Гүйлгээний төрөл</th>
                <th className="ant-table-cell interval-header-2 border-last border-bottom" colSpan={16}>ACQUIRER</th>
                <th className="ant-table-cell interval-header-2 border-last border-bottom" colSpan={16}>ISSUER</th>
                <th className="ant-table-cell interval-header-2 border-last border-bottom" colSpan={16}>BOM</th>
                <th className="ant-table-cell interval-header-2 border-last border-bottom" colSpan={16}>E-PAY</th>
                <th className="ant-table-cell" rowSpan={3}>Үйлдэл</th>
              </tr>
              <tr>
                {[1, 2, 3, 4].map((index) => (
                  <>
                    <th key={index + 1} className="ant-table-cell border-last" colSpan={4}>Interval 1</th>
                    <th key={index + 2} className="ant-table-cell border-last" colSpan={4}>Interval 2</th>
                    <th key={index + 3} className="ant-table-cell border-last" colSpan={4}>Interval 3</th>
                    <th key={index + 4} className="ant-table-cell border-last" colSpan={4}>Interval 4</th>
                  </>
                ))}
              </tr>
              <tr>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((index) => (
                  <>
                    <th className="ant-table-cell border-bottom" width="100" key={index + 1}>Төрөл</th>
                    <th className="ant-table-cell border-bottom" width="100" key={index + 2}>Шимтгэл</th>
                    <th className="ant-table-cell border-bottom" width="100" key={index + 3}>Бага дүн</th>
                    <th className="ant-table-cell border-last border-bottom" width="100" key={index + 4}>Их дүн</th>
                  </>
                ))}
              </tr>
            </thead>
          )} tbody={(row, index, page, limit) => {
            let customIndex = (page * limit) + index - limit + 1;
            return (
              <tbody key={index} className="ant-table-tbody">
                <tr>
                  <td className="ant-table-cell" rowSpan={6}>{customIndex}</td>
                  <td className="ant-table-cell sticky-column" rowSpan={6}>
                    <div>{row.operator_name}</div>
                    <Tag>{row.operator_code}</Tag>
                  </td>
                </tr>
                {row.settings.slice(3, 6).map((fee, index) => (
                  <tr key={index}>
                    {index === 0 && (
                      <td className="ant-table-cell sticky-column-2" rowSpan={3}>
                        Interbank
                      </td>
                    )}
                    <td className="ant-table-cell sticky-column-3 border-last">
                      <b>{fee.transaction_type}</b>
                    </td>
                    {
                      renderFeeByInterval(fee.intervals, index)
                    }
                    {index === 0 && (
                      <td className="ant-table-cell" rowSpan={1}>
                        <RowAction
                          actions = {{
                            edit: "Засварлах"
                          }}
                          onClick={() => { history.push(`/fee/settings/${row.operator_id}`); }}
                        />
                      </td>
                    )}
                    {index === 1 && (
                      <td className="ant-table-cell" rowSpan={1}>{row.setting_status === "ACTIVE" ? <Tag color="green">ИДЭВХТЭЙ</Tag> : <Tag color="red">ИДЭВХГҮЙ</Tag>}</td>
                    )}

                    {index === 2 && (
                      <td className="ant-table-cell" rowSpan={1}>{moment(row.setting_status_date).format("YYYY.MM.DD HH:mm")}</td>
                    )}
                  </tr>
                ))}
              </tbody>
            );
          }} />
      </PageContent>
    </PageContainer>
  );
};

const InoutTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
  .sticky-column {
    position: sticky;
    left: 0;
    background: #fff;
    z-index: 1;
  }
  .sticky-column-head {
    position: sticky;
    left: 0;
    background: #fafafa;
    z-index: 1;
  }
  .sticky-column-2 {
    position: sticky;
    left: 114px;
    background: #fff;
    z-index: 1;
  }

  .sticky-column-2-1 {
    position: sticky;
    left: 114px;
    background: #fafafa;
    z-index: 1;
  }
  .sticky-column-3 {
    position: sticky;
    left: 191px;
    background: #fff;
    z-index: 1;
  }
  .interval-header{
    border-top: 1px solid #000; /* Make borders bold */
    border-left: 1px solid #000; /* Highlight left border */
    border-right: 1px solid #000;
    border-bottom: 1px solid #000; /* Highlight right border */
  }

  .interval-header-2{
    border-top: 1px solid #000; /* Make borders bold */
    /* border-left: 1px solid #000; Highlight left border */
    border-right: 1px solid #000;
    border-bottom: 1px solid #000; /* Highlight right border */
  }

  .border-first{
    border-left: 1px solid #000;
  }

  .border-last{
    border-right: 1px solid #000 !important;
  }

  .border-bottom{
    border-bottom: 1px solid #000 !important;
  }

  .sticky-header {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 3; /* Higher z-index to stay above the columns */
  }
`;
