import React from "react";
import { Table } from "antd";
import moment from "moment";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";
import formatNumber from "../../utils/format";

const NetSettlementTable = (
  {
    paricipment,
    loadData,
    data,
    pagination = true,
    rowExpandable,
    limit: initialLimit = 100,
    filters,
    items: rows,
    setHeaderIndexes,
    setSubheaderIndexes,
    perPage,
    getData,
    value,
    el,
    headerIndexes,
    subheaderIndexes,
    options,
    tableLoading,
    onTableChange = () => {},
    onResult = () => {},
    ...rest
  },
  ref
) => {
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  // const [tableFilter, setTableFilter] = React.useState({});

  const [field, setField] = React.useState();
  const [sort, setSort] = React.useState();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(perPage);
  const [pageCount, setPageCount] = React.useState(1);
  const [totalEl, setTotalEl] = React.useState(1);

  const initialRenderPage = React.useRef(true);
  const initialRenderLimit = React.useRef(true);

  const [tableheaderIdx, setTableheaderIdx] = React.useState(headerIndexes);
  const [tablesubheaderIdx, setTablesubheaderIdx] =
    React.useState(setSubheaderIndexes);

  const reload = React.useCallback(
    async (signal) => {
      if (!loadData) return;
      setLoading(true);

      const res = await loadData(
        {
          filter: {
            query: "",
            ...filters,
          },
          order: {
            [field]: sort,
          },
          offset: {
            page : page,
            limit: limit,
          },
        },
        { signal }
      );
      if (res.type === "error") return console.log("error", res.payload);

      setItems(
        res.rows.map((row, index) => ({
          ...row,
          rowKey: index + 1,
        }))
      );
      setTotal(res.count);

      if (onResult) onResult(res);

      setLoading(false);
    },
    [filters, limit, loadData, field, sort, page]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  React.useImperativeHandle(ref, () => ({
    reload() {
      reload();
    },
    setItems(items) {
      setItems(items);
    },
    getLoading() {
      return loading;
    },
    setLoading(loadStatus) {
      setLoading(loadStatus);
    },
    getPage() {
      return page;
    },
    getLimit() {
      return limit;
    },
  }));

  React.useEffect(() => {
    if (rows) {
      setItems(rows);
      setTotal(rows.length);
    }
  }, [rows]);

  React.useEffect(() => {
    const listData = [];

    if (data != null && data.length > 0) {
      if (data && Array.isArray(data)) {
        let totalDebut = 0;
        let totalCredit = 0;
        data.forEach((el) => {
          const selectedOption = options.find(
            (option) => option.value === el.operatorCode
          );
          listData.push({
            paricipment : selectedOption.name,
            debitAmount : el.debitAmount,
            creditAmount: el.creditAmount,
          });
          totalDebut += el.debitAmountFloat;
          totalCredit += el.creditAmountFloat;
        });
        listData.push({
          paricipment : "Нийт дүн",
          debitAmount : formatNumber(totalDebut),
          creditAmount: formatNumber(totalCredit),
        });
      }
      setItems(listData);
    }
  }, [data]);

  React.useEffect(() => {
    if (initialRenderPage.current) {
      initialRenderPage.current = false;
    } else {
      getData(el);
    }
  }, [page]);

  React.useEffect(() => {
    if (initialRenderLimit.current) {
      initialRenderLimit.current = false;
    } else {
      getData(el);
    }
  }, [limit]);

  const useHeader = ({ type, onAction, s3host }) => {
    const onLastCell = (_, index) => {
      return {
        className: items.length - 1 === index ? "header-cell" : "",
      };
    };

    return [
      {
        title   : "Хаалтын хамрах үе",
        children: [
          {
            title : "Хаалтын оролцогч",
            render: (record, index) => {
              // console.log("record", record);
              return <div style={{ padding: "8px" }}>{record.paricipment}</div>;
            },
            onCell: onLastCell,
          },
        ],
      },
      {
        title   : `Эхэлсэн цаг ${filters.startDate && moment(filters.startDate).format("YYYY-MM-DD")}`,
        children: [
          {
            title : "Кредит дүн",
            render: (record, index) => {
              return <div style={{ padding: "8px" }}>{record.debitAmount}</div>;
            },
            onCell: onLastCell,
          },
        ],
        // onCell: sharedOnCell,
      },
      {
        title   : `Дууссан цаг ${filters.endDate && moment(filters.endDate).format("YYYY-MM-DD")}`,
        children: [
          {
            title : "Дебит дүн",
            render: (record, index) => {
              return (
                <div style={{ padding: "8px" }}>{record.creditAmount}</div>
              );
            },
            onCell: onLastCell,
          },
        ],
        // onCell: sharedOnCell,
      },
    ];
  };

  return (
    <>
      <Table
        {...{
          scroll    : { x: 100 },
          bordered  : true,
          columns   : useHeader({}),
          dataSource: items,
          rowExpandable,
          pagination: false,
          ...rest,
        }}
        loading={tableLoading}
        rowKey={(record, index) => record.id || index}
      />
      {/* <Box
        className="custom-pagination"
        style={{
          marginTop: "0px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          component="div"
          count={totalEl}
          page={page}
          rowsPerPage={limit}
          pagination={{
            showSizeChanger: false
          }}
          showSizeChanger={false}
          sx={{
            "& p": {
              marginTop: "auto",
              marginBottom: "auto",
            },
            ".MuiTablePagination-actions > button:first-of-type": {
              display: "none",
            },
            ".MuiTablePagination-actions > button:last-of-type": {
              display: "none",
            },
            ".MuiTablePagination-displayedRows": {
              display: "none",
            },
          }}
        />
        <Pagination
          count={pageCount}
          page={page}
          onChange={(_, newPage) => {
            setPage(newPage);
          }}
          color="primary"
          shape="rounded"
        />
      </Box> */}
    </>
  );
};

export default React.forwardRef(NetSettlementTable);
