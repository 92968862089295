import React from "react";
import MonthlyTransactionReportPage from "../../components/ReportPage/monthlyTransactionReportPage";

export default () => {
  return (
    <MonthlyTransactionReportPage
      reportType={"SUCCESS"}
      fileName={"monthly_successful_transaction"}
      reportName={"ePay-ийн нийлбэр амжилттай гүйлгээний тайлан"}
    />
  );
};
