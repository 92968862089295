import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form as AntForm, message } from "antd";
import { ImageUpload } from "../../components";
import { FormItem, Input, Select, Form, Checkbox } from "formik-antd";
import { mailformat, phoneNumber, formItemLayout, tailFormItemLayout } from "../../utils";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { operator as operatorApi } from "../../apis";

const FormSchema = () => Yup.object({
  type   : Yup.string().required("Заавал бөглөнө!"),
  name   : Yup.string().max(100, "Хэтэрхий урт байна!").required("Заавал бөглөнө!"),
  name_en: Yup.string().max(100, "Хэтэрхий урт байна!").required("Заавал бөглөнө!"),
  logo   : Yup.string().trim().optional("Заавал бөглөнө!").nullable(),
  email  : Yup.string()
    .trim()
    .matches(mailformat, "Зөвхөн и-мэйл хаяг!")
    .email("Та зөвхөн и-мэйл хаяг!")
    .optional("Заавал бөглөнө!")
    .nullable(),
  phone: Yup.string()
    .trim()
    .matches(
      /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/,
      "Зөвхөн утасны дугаар!"
    )
    .max(8, "Зөвхөн утасны дугаар!")
    .min(8, "Зөвхөн утасны дугаар!")
    .matches(phoneNumber, "Зөвхөн утасны дугаар!")
    .optional().nullable(),
  website     : Yup.string().url("Вэбсайтын холбоос буруу байна!").optional().nullable(),
  address     : Yup.string().optional().nullable(),
  is_active   : Yup.boolean().required(),
  is_suspended: Yup.boolean().required(),
});

export default ({ action }) => {
  const history = useHistory();
  const { roles } = useSelector((state) => state.general);
  const [data] = React.useState({
    type        : undefined,
    name        : undefined,
    name_en     : undefined,
    email       : undefined,
    logo        : undefined,
    phone       : undefined,
    website     : undefined,
    address     : undefined,
    is_active   : false,
    is_suspended: false,
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const onSubmit = async (values) => {
    try {
      if (action && action[0] === "update") {
        await operatorApi.update(data.id, values);
      } else {
        await operatorApi.create(values);
      }

      message.success("Таны хүсэлт амжилттай!");

      history.push("/operator/list");
    } catch (err) {
      message.error(err?.message || "Таны хүсэлт амжилтгүй!");
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema()}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => {
          return (
            <Form {...formItemLayout}>
              <FormItem name="type" label="Төрөл" required>
                <Select name="type" placeholder="Төрөл">
                  <Select.Option value="WALLET">Wallet</Select.Option>
                  <Select.Option value="MERCHANT">Merchant</Select.Option>
                </Select>
              </FormItem>
              <FormItem name="logo" label="Лого">
                <ImageUpload action="/api/general/upload" name="logo" />
              </FormItem>
              <FormItem name="name" label="Нэр" required>
                <Input name="name" placeholder="Нэр" />
              </FormItem>
              <FormItem name="name_en" label="Нэр (EN)" required>
                <Input name="name_en" placeholder="Нэр (EN)" />
              </FormItem>
              <FormItem name="email" label="Имэйл хаяг">
                <Input name="email" placeholder="Имэйл хаяг" />
              </FormItem>
              <FormItem name="phone" label="Утас">
                <Input name="phone" placeholder="Утас" />
              </FormItem>
              <FormItem name="address" label="Хаяг байршил">
                <Input.TextArea name="address" placeholder="Хаяг" maxLength={500} showCount rows={3} />
              </FormItem>
              <FormItem name="is_active" label="Идэвхтэй эсэх" required>
                <Checkbox name="is_active" />
              </FormItem>
              <FormItem name="is_suspended" label="Хязгаарлагдсан эсэх" required>
                <Checkbox name="is_suspended" />
              </FormItem>
              <AntForm.Item {...tailFormItemLayout}>
                <Button htmlType="submit" type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>Хадгалах</Button>
                <Button onClick={() => history.push("/system/user")}>Болих</Button>
              </AntForm.Item>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
