import React from "react";
import { Col, Row, Spin } from "antd";
import { user as userApi } from "../../apis";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Form from "./Form";

export default () => {
  const params = useParams();
  const [user, fetching] = useFetch(userApi.get, params.id)([]);

  return (
    <Spin spinning={fetching}>
      <PageContainer>
        <PageHeader title="Хэрэглэгч засварлах" />
        <Row>
          <Col xs={24} sm={24} md={20} lg={18} xl={14} xxl={12}>
            <PageContent>
              {!fetching && <Form action={["update", {
                ...user,
                roles: user.roles.map(r => r.code)
              }]} />}
            </PageContent>
          </Col>
        </Row>
      </PageContainer>
    </Spin>
  );
};