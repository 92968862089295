import React from "react";
import { Tag, Button, Avatar, Modal, Input, DatePicker, Select } from "antd";
import { user as userApi, operator as operatorApi } from "../../apis";
import { PlusOutlined, UserOutlined, SearchOutlined } from "@ant-design/icons";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { MyTable, RowAction } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Filter } from "../../components/Design";
import usePermission from "../../hooks/usePermission";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useFetch from "../../hooks/useFetch";
import styled from "styled-components";

export default () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const params = useParams();
  const [query, setQuery] = React.useState("");
  const { s3host, roles, isMobile } = useSelector(state => state.general);
  const { checkPermission } = usePermission();
  const [operators] = useFetch(operatorApi.select)([]);

  const [filters, setFilters] = React.useState({
    type      : params.type,
    query     : "",
    role      : undefined,
    start_date: "",
    end_date  : "",
    is_active : undefined
  });

  const columns = useHeader({
    s3host,
    roles,
    type    : params.type,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "remove": {
          Modal.confirm({
            title     : "Баталгаажуулах",
            icon      : <ExclamationCircleOutlined />,
            content   : "Та үүнийг устгахдаа итгэлтэй байна уу!!!",
            okText    : "Устгах",
            cancelText: "Болих",
            onOk      : async () => {
              await userApi.remove(record.id);

              myTableRef.current.reload();
            },
          });
          break;
        }
        case "edit": {
          history.push(`/user/edit/${record.id}`);
          break;
        }
        default:
      }
    }
  });

  let timeout;

  React.useEffect(() => {
    if (timeout)
      clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters(state => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  React.useEffect(() => {
    setFilters({
      type      : params.type,
      query     : "",
      role      : undefined,
      is_active : undefined,
      start_date: undefined,
      end_date  : undefined
    });
  }, [params.type]);

  const dateFilter = (e)=> {
    setFilters({ ...filters, start_date: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"), end_date: e &&moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm") });
  };

  const onClear = () => {
    setQuery("");
    setFilters({
      type      : params.type,
      query     : "",
      role      : undefined,
      is_active : undefined,
      start_date: undefined,
      end_date  : undefined
    });
  };

  return (
    <PageContainer>
      <PageHeader title="Систем хэрэглэгч" extra={[<Button type="primary" shape={isMobile && "circle"} onClick={() => history.push(`/user/new/${params.type}`)} icon={<PlusOutlined />}>{!isMobile && "Хэрэглэгч нэмэх"}</Button> ]}/>
      <PageContent>
        <Filter extra={[
          <DatePicker.RangePicker value={[filters.start_date && moment(filters.start_date), filters.end_date && moment(filters.end_date)]} onChange={dateFilter} />,
          <Button onClick={onClear}>Цэвэрлэх</Button>]} >
          <Input className="grid-item" prefix={<SearchOutlined />} value={query} placeholder="Хайлт хийх..." onChange={e => setQuery(e.target.value)} allowClear />
          {params.type === "ADMIN" ? (
            <Select className="grid-item" value={filters.role} style={{ width: "150px" }} onChange={e => setFilters({ ...filters, role: e })} placeholder="Хандах эрх" allowClear>
              {roles?.map((item, index) => {
                return <Select.Option key={index} value={item.code}>{item.name}</Select.Option>;
              })}
            </Select>
          ) : (
            <Select className="grid-item" value={filters.role} style={{ width: "150px" }} onChange={e => setFilters({ ...filters, operator_id: e })} placeholder="Харилцагч" allowClear>
              {operators?.map((item, index) => (
                <Select.Option value={item.id} key={index} >{item.name}</Select.Option>
              ))}
            </Select>
          )}
          <Select className="grid-item" value={filters.is_active} style={{ width: "130px" }} onChange={e => setFilters({ ...filters, is_active: e })} placeholder="Төлөв" allowClear>
            <Select.Option key={"true"} value={true}>Идэвхтэй</Select.Option>
            <Select.Option key={"false"} value={false}>Идэвхгүй</Select.Option>
          </Select>
        </Filter>

        <Table
          ref={myTableRef}
          columns={columns}
          filters={filters}
          loadData={userApi.list}
          rowKey={(record) => record?.rowKey}/>
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ type, onAction, s3host }) => {
  return [{
    title    : "№",
    dataIndex: "rowKey",
    width    : "20px"
  }, {
    title : "Зураг",
    render: (record) => {
      return <Avatar src={`${s3host}${record.avatar}`} icon={<UserOutlined />} />;
    }
  }, (
    type === "ADMIN" ? {
      title : "Хандах эрх",
      render: (record) => {
        return record.roles.map((item, index) => {
          return <Tag key={index} >{item.name}</Tag>;
        });
      }
    } : {
      title : "Харилцагч",
      render: (record) => {
        return record?.operator?.name || "-";
      }
    }
  ), {
    title : "Овог нэр",
    render: (record) => {
      return (<div>{record.lastname} {record.firstname}</div>);
    }
  }, {
    title : "Имэйл хаяг",
    render: (record) => {
      return (<div>{record.email}</div>);
    }
  }, {
    title : "Утас",
    render: (record) => {
      return (<div>{record.phone || "-"}</div>);
    }
  }, {
    title : "Хаяг",
    render: (record) => {
      return (<div>{record.address || "-"}</div>);
    }
  }, {
    title : "Төлөв",
    render: (record) => {
      return record.is_active ? <Tag color="green">Идэвхтэй</Tag> : <Tag color="red">Идэвхгүй</Tag>;
    }
  }, {
    title : "Огноо",
    render: (record) => {
      return (<div>{moment(record.created_at).format("YYYY-MM-DD HH:mm")}</div>);
    }
  }, {
    title : "Үйлдэл",
    width : 100,
    render: (record) => {
      return (
        <RowAction
          actions = {{
            edit  : "Засварлах",
            remove: "Устгах",
          }}
          onClick={(key) => onAction(key, record)}
        />
      );
    }
  }];
};

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;