import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form as AntForm, Row, Col, message } from "antd";
import { ImageUpload } from "../../components";
import { FormItem, Input, Select, Form, Checkbox } from "formik-antd";
import { phoneNumber, mongolText, uppercaseRegex, lowercaseRegex, numericRegex, formItemLayout, tailFormItemLayout } from "../../utils";
import { CloseOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { user as userApi, operator as operatorApi } from "../../apis";
import useFetch from "../../hooks/useFetch";

const FormSchema = () => Yup.object({
  type : Yup.string().required(),
  roles: Yup.array().when("type", {
    is  : "ADMIN",
    then: Yup.array()
      .of(Yup.string().nullable().required("Заавал бөглөнө!"))
      .min(1, "Заавал бөглөнө!")
      .required("Заавал бөглөнө!")
  }).optional().nullable(),
  operator_id: Yup.string().when("type", {
    is  : "OPERATOR",
    then: Yup.string().required("Заавал бөглөнө!")
  }).optional().nullable(),
  lastname: Yup.string()
    .trim()
    .max(30, "Хэтэрхий урт байна!")
    .matches(mongolText, "Та кирилээр.")
    .required("Заавал бөглөнө!"),
  firstname: Yup.string()
    .trim()
    .max(30, "Хэтэрхий урт байна!")
    .matches(mongolText, "Та кирилээр.")
    .required("Заавал бөглөнө!"),
  avatar: Yup.string().trim().optional("Заавал бөглөнө!").nullable(),
  email : Yup.string()
    .trim()
    .email("Та зөвхөн и-мэйл хаяг!")
    .required("Заавал бөглөнө!"),
  phone: Yup.string()
    .trim()
    .matches(
      /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/,
      "Зөвхөн утасны дугаар!"
    )
    .max(8, "Зөвхөн утасны дугаар!")
    .min(8, "Зөвхөн утасны дугаар!")
    .matches(phoneNumber, "Зөвхөн утасны дугаар!")
    .optional().nullable(),
  password: Yup.string()
    .matches(uppercaseRegex, "Том үсэг оруулна уу!")
    .matches(lowercaseRegex, "Жижиг үсэг оруулна уу!")
    .matches(numericRegex, "Та тоо оруулна уу!")
    .min(8, "Нууц үг 8-с их орон байх хэрэгтэй!")
    .optional().nullable(),
  address  : Yup.string().optional().nullable(),
  position : Yup.string().optional().nullable(),
  is_active: Yup.boolean().required()
});

export default ({ action }) => {
  const history = useHistory();
  const params = useParams();
  const { roles } = useSelector((state) => state.general);
  const [operators] = useFetch(operatorApi.select)([]);
  const [password, setPassword] = React.useState(false);
  const [data] = React.useState({
    type       : params.type,
    lastname   : undefined,
    firstname  : undefined,
    phone      : undefined,
    operator_id: undefined,
    email      : undefined,
    avatar     : undefined,
    address    : undefined,
    position   : undefined,
    roles      : undefined,
    is_active  : false,
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const onSubmit = async (values) => {
    try {
      if (action && action[0] === "update") {
        await userApi.update(data.id, {
          type: data.type,
          ...values
        });

        history.push(`/user/list/${data.type}`);
      } else {
        await userApi.create({
          type: params.type,
          ...values
        });

        history.push(`/user/list/${params.type}`);
      }

      message.success("Таны хүсэлт амжилттай!");
    } catch (err) {
      message.error(err?.message || "Таны хүсэлт амжилтгүй!");
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema()}
        onSubmit={onSubmit}>
        {({ isSubmitting, errors }) => {
          console.log(errors);
          return (
            <Form {...formItemLayout}>
              <Input type="hidden" name="type" />

              <FormItem name="email" label="Имэйл хаяг" required>
                <Input name="email" placeholder="Имэйл хаяг" />
              </FormItem>
              {data.type === "ADMIN" ? (
                <FormItem name="roles" label="Хандах эрх" required>
                  <Select name="roles" mode="multiple" placeholder="Хандах эрх">
                    {roles?.map((item, index) => (
                      <Select.Option value={item.code} key={index} >{item.name}</Select.Option>
                    ))}
                  </Select>
                </FormItem>
              ) : (
                <FormItem name="operator_id" label="Харилцагч" required>
                  <Select name="operator_id" placeholder="Харилцагч">
                    {operators?.map((item, index) => (
                      <Select.Option value={item.id} key={index} >{item.name}</Select.Option>
                    ))}
                  </Select>
                </FormItem>
              )}
              {password && (
                <FormItem label="Нууц үг" name="password">
                  <Row gutter={12}>
                    <Col>
                      <Input name="password" placeholder="Нууц үг" />
                    </Col>
                    <Col>
                      <Button type="default" shape="circle" onClick={() => setPassword(!password)}><CloseOutlined /></Button>
                    </Col>
                  </Row>
                </FormItem>
              )}
              {!password && (
                <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 0 }}>
                  <Button type="default" onClick={() => setPassword(!password)} block>Нууц үг солих</Button>
                </AntForm.Item>
              )}
              <FormItem name="avatar" label="Зураг">
                <ImageUpload action="/api/general/upload" name="avatar" />
              </FormItem>
              <FormItem name="lastname" label="Овог" required>
                <Input name="lastname" placeholder="Овог" />
              </FormItem>
              <FormItem name="firstname" label="Нэр" required>
                <Input name="firstname" placeholder="Нэр" />
              </FormItem>
              <FormItem name="position" label="Албан тушаал">
                <Input name="position" placeholder="Албан тушаал" />
              </FormItem>
              <FormItem name="phone" label="Утас">
                <Input name="phone" placeholder="Утас" />
              </FormItem>
              <FormItem name="address" label="Хаяг байршил">
                <Input.TextArea name="address" placeholder="Хаяг" maxLength={500} showCount rows={3} />
              </FormItem>
              <FormItem name="is_active" label="Идэвхтэй эсэх" required>
                <Checkbox name="is_active" />
              </FormItem>
              <AntForm.Item {...tailFormItemLayout}>
                <Button htmlType="submit" type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>Хадгалах</Button>
                <Button onClick={() => history.push(`/user/list/${params.type}`)}>Болих</Button>
              </AntForm.Item>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
