import React from "react";
import BillingInvoiceReportPage from "../../components/ReportPage/billingInvoiceReportPage";

export default () => {
  return (
    <BillingInvoiceReportPage
      reportType={"SUCCESS"}
      fileName={"monthly_bill"}
      reportName = {"Харилцагчийн шимтгэл тооцоо нэхэмжлэх тайлан"}
      isSuccess={true}
    />
  );
};