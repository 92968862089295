import React from "react";
import { Col, Row } from "antd";
import { PageHeader, PageContainer, PageContent } from "../../components/Layout";
import Form from "./Form";

export default () => {
  return (
    <PageContainer>
      <PageHeader title="Хэрэглэгч нэмэх"/>

      <Row>
        <Col xs={24} sm={24} md={20} lg={18} xl={14} xxl={12}>
          <PageContent>
            <Form action={["create"]} />
          </PageContent>
        </Col>
      </Row>
    </PageContainer>
  );
};