import styled from "styled-components";
import React from "react";
import { Space } from "antd";

const Filter = ({ extra, children, className }) => {
  return <Filters className={className && className}>
    <Space>
      {children}
    </Space>
    <Space>
      {extra}
    </Space>
  </Filters>;
};

const Filters = styled.div`
  display: flex;
  padding: 20px 0;  
  gap: 10px;
  flex-flow: row wrap;
  justify-content: space-between;  

  &.flex-end {
    justify-content: flex-end;   
  }
  
  &.padding-0 {
    padding: 0;
  }
  .grid-item {
    width: 220px !important;
    max-width: 220px;
  }

  .ant-picker , .ant-picker-range {
    width: 245px;
  }
  
  .ant-space-item, .ant-space-horizontal, .ant-space-align-center{ 
      flex-wrap: wrap;
  }
  .ant-radio-group {
    &.ant-radio-group-solid {
      
    min-width: max-content !important;
    flex-wrap: nowrap !important;
    }
  }
  @media only screen and (max-width: 600px) {
    .ant-space {
      width: 100% !important; 
      flex-wrap: wrap;
    }
    .ant-picker , .ant-picker-range {
      width: 100% !important;
    }
    .ant-space-item {
      width: 100% !important; 
      flex-wrap: wrap;
    }
    .grid-item { 
      min-width: 100% !important;
      max-width: 100% !important;
    }
  }   
`;

export {
  Filter
};