import React from "react";
import { Form, Input, Checkbox, FormItem } from "formik-antd";
import { Formik } from "formik";
import { Button, Card, notification } from "antd";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { auth as authApi } from "../../apis";
import LogoImage from "../../assets/logo.png";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import colors from "../../colors";

const FormSchema = Yup.object().shape({
  username: Yup.string().required("Нэвтрэх нэр оруулна уу"),
  password: Yup.string().required("Нууц үг оруулна уу"),
});

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { env } = useSelector(state => state.general);

  const openNotificationWithIcon = (data) => {
    notification[data.type]({
      message    : data.message,
      description: "",
    });
  };

  const onSubmit = async (data) => {
    try {
      const res = await authApi.login(data);
      dispatch({
        type   : "auth.change",
        payload: res
      });
    } catch (err) {
      openNotificationWithIcon({ type: "error", message: "Таны нэвтрэх нэр болон нууц үг буруу байна!" });
    }
  };

  return (
    <Container>
      <Card className="login">
        <div className="logo">
          <img src={LogoImage} />
          <h2>
            <span>E-PAY</span>
            <p style={{ color: colors.primary }}>Admin dashboard</p>
          </h2>
        </div>
        <Formik
          initialValues={{
            username: undefined,
            password: undefined,
          }}
          validationSchema={FormSchema}
          onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form layout={"vertical"}>
              <FormItem name="username" label="Нэвтрэх нэр">
                <Input prefix={<UserOutlined />} name="username" placeholder="Нэвтрэх нэр" />
              </FormItem>
              <FormItem name="password" label="Нууц үг" >
                <Input.Password prefix={<LockOutlined />} name="password" type="password" className="form-input" />
              </FormItem>
              <div className="forgot">
                <a href="#">Нууц үгээ мартсан!</a>
              </div>
              <div className="remember">
                <Checkbox name="remember">Намайг сана</Checkbox>
              </div>
              <Button htmlType="submit" type="primary" loading={isSubmitting} block>Нэвтрэх</Button>
            </Form>
          )}
        </Formik>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  background: #f8f8f8;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  display: flex;
  flex-direction: center;
  align-items: center;
  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    h2 {
      margin: 0;
      p {
        font-size: 12px;
        margin: 0;
        font-weight: 500;
      }
    }
    img {
      height: 60px;
      margin-right: 10px;
    }
  }

  .login {
    background: #fff;
    border: 1px solid #e8e8e8;
    width: 320px;
    margin: auto;
    padding-bottom: 10px;
    .alert {
      font-size: 12px;
      padding: 10px 10px 10px 35px;
      margin-bottom: 20px;
    }
    .forgot {
      text-align: right;
      margin-top: -20px;
    }
    .remember {
      margin-bottom: 10px;
      margin-top: 20px;
    }
  }
`;

export default Login;