import React from "react";
import { Tag, Button, Avatar, Modal, Input, DatePicker, Select } from "antd";
import { operator as operatorApi } from "../../apis";
import { PlusOutlined, UserOutlined, SearchOutlined } from "@ant-design/icons";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { MyTable, RowAction } from "../../components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Filter } from "../../components/Design";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import usePermission from "../../hooks/usePermission";
import moment from "moment";
import styled from "styled-components";

export default () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const [query, setQuery] = React.useState("");
  const { s3host, roles, isMobile } = useSelector(state => state.general);
  const { checkPermission } = usePermission();

  const [filters, setFilters] = React.useState({
    query     : "",
    start_date: "",
    end_date  : "",
    is_active : undefined
  });

  const columns = useHeader({
    s3host,
    roles,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "remove": {
          Modal.confirm({
            title     : "Баталгаажуулах",
            icon      : <ExclamationCircleOutlined />,
            content   : "Та үүнийг устгахдаа итгэлтэй байна уу!!!",
            okText    : "Устгах",
            cancelText: "Болих",
            onOk      : async () => {
              await operatorApi.remove(record.id);

              myTableRef.current.reload();
            },
          });
          break;
        }
        case "edit": {
          history.push(`/operator/edit/${record.id}`);
          break;
        }
        default:
      }
    }
  });

  let timeout;

  React.useEffect(() => {
    if (timeout)
      clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters(state => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  const dateFilter = (e)=> {
    setFilters({ ...filters, start_date: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"), end_date: e &&moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm") });
  };

  const onClear = () => {
    setQuery("");
    setFilters({
      query     : "",
      is_active : undefined,
      start_date: undefined,
      end_date  : undefined
    });
  };

  return (
    <PageContainer>
      <PageHeader title="Харилцагч" extra={[<Button type="primary" shape={isMobile && "circle"} onClick={() => history.push("/operator/new")} icon={<PlusOutlined />}>{!isMobile && "Харилцагч нэмэх"}</Button> ]}/>
      <PageContent>
        <Filter extra={[
          <DatePicker.RangePicker key="RangeDatePicker" value={[filters.start_date && moment(filters.start_date), filters.end_date && moment(filters.end_date)]} onChange={dateFilter} />,
          <Button key="ClearButton" onClick={onClear}>Цэвэрлэх</Button>]} >
          <Input key="SearchInput" className="grid-item" prefix={<SearchOutlined />} value={query} placeholder="Хайлт хийх..." onChange={e => setQuery(e.target.value)} allowClear />
          <Select key="IsActiveDropDown" className="grid-item" value={filters.is_active} style={{ width: "130px" }} onChange={e => setFilters({ ...filters, is_active: e })} placeholder="Төлөв" allowClear>
            <Select.Option key={"true"} value={true}>Идэвхтэй</Select.Option>
            <Select.Option key={"false"} value={false}>Идэвхгүй</Select.Option>
          </Select>
        </Filter>

        <Table
          ref={myTableRef}
          columns={columns}
          filters={filters}
          loadData={operatorApi.list}
          rowKey={(record) => record?.rowKey}/>
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, s3host }) => {
  return [{
    title    : "№",
    dataIndex: "rowKey",
    width    : "20px"
  }, {
    title : "Зураг",
    render: (record) => {
      return <Avatar src={`${s3host}${record.logo}`} size={40} shape="square" />;
    }
  }, {
    title : "Дугаар",
    render: (record) => {
      return <Tag>{record.code}</Tag>;
    }
  }, {
    title : "Нэр",
    render: (record) => {
      return (
        <div>
          <div>{record.name}</div>
          <div>{record.name_en || "-"}</div>
        </div>
      );
    }
  }, {
    title : "Төрөл",
    render: (record) => {
      const colors = {
        WALLET  : "blue",
        MERCHANT: "red"
      };

      return <Tag color={colors[record.type]}>{record.type}</Tag>;
    }
  }, {
    title : "Вэб сайт",
    render: (record) => {
      if (!record.website)
        return "-";

      return (<a href={record.website} target="_blank">{record.website}</a>);
    }
  }, {
    title : "Имэйл хаяг",
    render: (record) => {
      return (<div>{record.email || "-"}</div>);
    }
  }, {
    title : "Утас",
    render: (record) => {
      return (<div>{record.phone || "-"}</div>);
    }
  }, {
    title : "Хаяг",
    render: (record) => {
      return (<div>{record.address || "-"}</div>);
    }
  }, {
    title : "Төлөв",
    render: (record) => {
      if (record.is_active === false)
        return <Tag color="red">Идэвхгүй</Tag>;

      if (record.is_suspended === true)
        return <Tag color="red">Хязгаарлагдсан {moment(record.suspended_date).format("YYYY.MM.DD HH:mm")}</Tag>;

      return <Tag color="green">Идэвхтэй</Tag>;
    }
  }, {
    title : "Огноо",
    render: (record) => {
      return (<div>{moment(record.created_at).format("YYYY-MM-DD HH:mm")}</div>);
    }
  }, {
    title : "Үйлдэл",
    width : 100,
    render: (record) => {
      return (
        <RowAction
          actions = {{
            edit  : "Засварлах",
            remove: "Устгах",
          }}
          onClick={(key) => onAction(key, record)}
        />
      );
    }
  }];
};
const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;