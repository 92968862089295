import React from "react";
import { useParams } from "react-router-dom";
import { feeSettings as feeSettingsApi } from "../../apis";
import { Spin } from "antd";
import { PageContainer, PageContent } from "../../components/Layout";
import Form from "./Form";

export default () => {
  const params = useParams();
  const [action, setAction] = React.useState([]);
  const reload = React.useCallback(async (signal) => {

    let res = await feeSettingsApi.get(params.id);

    let payload = res?.settings?.reduce((accumulator, iterator) => {
      return {
        ...accumulator,
        [`${iterator?.transaction_mode}_${iterator?.transaction_type}_intervals`.toLowerCase()]: iterator?.intervals || []
      };
    }, {
      operator_id                 : res.operator_id,
      operator_name               : res.operator_name,
      operator_code               : res.operator_code,
      inbank_payment_intervals    : [],
      inbank_purchase_intervals   : [],
      inbank_transfer_intervals   : [],
      interbank_payment_intervals : [],
      interbank_purchase_intervals: [],
      interbank_transfer_intervals: []
    });

    setAction(["update", payload]);
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);
    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      <PageContent>
        <Spin spinning={!action[1]}>
          {action[1] && <Form action={action} />}
        </Spin>
      </PageContent>
    </PageContainer>
  );
};
