import React from "react";
import MonthlyTransactionReportPage from "../../components/ReportPage/monthlyTransactionReportPage";

export default () => {
  return (
    <MonthlyTransactionReportPage
      reportType={"REFUND"}
      fileName={"monthly_unsuccessful_transaction"}
      reportName={"ePay-ийн нийлбэр амжилтгүй гүйлгээний тайлан"}
    />
  );
};
