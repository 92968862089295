import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form as AntForm, message } from "antd";
import { FormItem, Input, Form, DatePicker, Select } from "formik-antd";
import { settlement as settlementApi, operator as operatorApi } from "../../apis";
import useFetch from "../../hooks/useFetch";

const FormSchema = () => Yup.object({
  id      : Yup.string().required("Заавал бөглөнө!"),
  schedule: Yup.array().of(Yup.date().required("Заавал бөглөнө!")).length(2, "Заавал бөглөнө!"),
  note    : Yup.string().required("Заавал бөглөнө!")
});

export default ({ onCancel }) => {
  const [operators] = useFetch(operatorApi.select)([]);
  const [data] = React.useState({
    operator_id: undefined,
    schedule   : [],
    note       : undefined
  });

  const onSubmit = async (values) => {
    console.log(values);
    try {
      await settlementApi.create(values.id, {
        start_date: values.schedule[0],
        end_date  : values.schedule[1],
        note      : values.note
      });

      message.success("Таны хүсэлт амжилттай!");

      onCancel(true);
    } catch (err) {
      message.error(err?.message || "Таны хүсэлт амжилтгүй!");
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema()}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => {
          return (
            <Form layout="vertical">
              <FormItem name="id" label="Харилцагч" required>
                <Select name="id" placeholder="Харилцагч сонгох">
                  {operators.map((row, index) => (
                    <Select.Option key={index} value={row.id}>{row.name}</Select.Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem name="schedule" label="Эхлэх дуусах огноо" required>
                <DatePicker.RangePicker name="schedule" style={{ width: "100%" }} />
              </FormItem>
              <FormItem name="note" label="Тооцоолол хийх тайлбар" required>
                <Input.TextArea name="note" placeholder="Тооцоолол хийх тайлбар" maxLength={500} showCount rows={3} />
              </FormItem>
              <AntForm.Item>
                <Button htmlType="submit" type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>Хадгалах</Button>
                <Button onClick={() => onCancel()}>Болих</Button>
              </AntForm.Item>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
