import React from "react";
import { Button, DatePicker, Select, message } from "antd";
import { PageContainer, PageHeader, PageContent } from "../Layout";
import NetSettlementTable from "../MyTable/netSettlementTable";
import { Filter } from "../Design";
import moment from "moment";
import styled from "styled-components";
import { fetchReportData, fetchReportFile } from "../../utils/reportRequest";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

export default ({ fileName, reportName }) => {
  const { accessToken } = useSelector((state) => state.auth);

  const [options, setOptions] = React.useState([]);

  const [value, setValue] = React.useState([]);

  const ref = React.createRef();

  const [data, setData] = React.useState(null);

  const [tableLoading, setTableLoading] = React.useState(false);

  React.useEffect(() => {
    console.log("value ==========> ", value);
    const allIncluded = value.includes("all");
    if ((options.length - 1) === value.length && !allIncluded) {
      setValue([...value, "all"]);
    } else if (allIncluded && value.length !== options.length) {
      setValue(value.filter((el) => el !== "all"));
    }
    if (value.length > 0){
      getData(value);
    } else {
      setData(null);
    }
  }, [value, options.length]);

  React.useEffect(() => {
    fetchReportData("/operator/list", "GET", accessToken).then((res) => {
      const listData = [];
      if (res.responseCode) {
        for (let index = 0; index < res.data.length; index++) {
          const selectedData = res.data[index];
          selectedData.label = selectedData.name;
          selectedData.value = selectedData.code;
          listData.push(selectedData);
          //   selectedData.tableRef = React.createRef();
          //   selectedData.displayData = null;
          //   selectedData.tablePageData = {
          //     request: {
          //       pageNo: 1,
          //       perPage: 5,
          //       sort: "",
          //     },
          //   };
          //   selectedData.headerIndexes = [];
          //   selectedData.setHeaderIndexes = (value) => {
          //     // console.log("setHeaderIndexes", value);
          //     selectedData.headerIndexes = [
          //       ...(selectedData.headerIndexes, value),
          //     ];
          //   };
          //   selectedData.subheaderIndexes = [];
          //   selectedData.setSubheaderIndexes = (value) => {
          //     // console.log("setSubheaderIndexes", value);
          //     selectedData.subheaderIndexes = [
          //       ...selectedData.subheaderIndexes,
          //       value,
          //     ];
          //   };
          //   listData.push(selectedData);
        }
        // console.log("listData", listData);
        if (listData.length > 0){
          let data = {};
          data.label = "Бүгд";
          data.value = "all";
          listData.unshift(data);
        }
        setOptions(listData);
      }
    });
  }, []);

  const [filters, setFilters] = React.useState({
    // startDate: "2020-04-11",
    // endDate  : "2026-04-11",
    startDate: "",
    endDate  : "",
  });

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      startDate: e && moment(e[0]).format("YYYY-MM-DDTHH:mm"),
      endDate  : e && moment(e[1]).format("YYYY-MM-DDTHH:mm"),
    });
  };

  React.useEffect(() => {
    if (value.length > 0 && filters.startDate !== "" && filters.endDate !== "") {
      getData(value);
    }
  }, [filters]);

  const onClear = () => {
    setFilters({
      startDate: "",
      endDate  : "",
    });
    setValue([]);
    setData(null);
  };

  const getData = (val) => {
    console.log("called ---------> ");
    // const idx = options.findIndex((option) => option.value === optionValue);
    // if (options[idx].tableRef.current != null) {
    //   options[idx].tableRef.current.setLoading(true);
    //   options[idx].tablePageData.request.pageNo =
    //     options[idx].tableRef.current.getPage();
    //   options[idx].tablePageData.request.perPage =
    //     options[idx].tableRef.current.getLimit();
    // }
    if (ref.current != null) {
      ref.current.setLoading(true);
      console.log("true here 1");
    }
    setTableLoading(true);
    fetchReportData("/report/net/settlement", "POST", accessToken, {
      // request: options[idx].tablePageData.request,
      ...filters,
      operatorCodes: val.filter((el) => el !== "all"),
      // operatorCodes: options[idx].code,
    })
      .then((data) => {
        console.log("ref.current ----> ", ref.current, data);
        if (ref.current != null) {
          ref.current.setLoading(false);
          console.log("false here 1");
        }
        setTableLoading(false);
        if (data.responseCode) {
          setData(data.data);
          // options[idx].displayData = data.data;
          // setOptions([...options]);
        } else {
          setData([]);
        }
        // if (options[idx].tableRef.current != null) {
        //   options[idx].tableRef.current.setLoading(false);
        // }
      })
      .catch((err) => {
        console.log("err ", err);
        if (ref.current != null) {
          ref.current.setLoading(false);
          console.log("false here 2");
        }
        setTableLoading(false);
        // if (options[idx].tableRef.current != null) {
        //   options[idx].tableRef.current.setLoading(false);
        // }
      });
  };

  return (
    <PageContainer>
      <PageHeader title={reportName} />
      <PageContent>
        <Filter
          extra={[
            <DatePicker.RangePicker
              key="RangeDatePicker3"
              // showTime={{
              //   hideDisabledOptions: true,
              //   defaultValue       : [
              //     dayjs("00:00:00", "HH:mm:ss"),
              //     dayjs("11:59:59", "HH:mm:ss"),
              //   ],
              // }}
              // format="YYYY-MM-DD HH:mm:ss"
              value={[
                filters.startDate && moment(filters.startDate),
                filters.endDate && moment(filters.endDate),
              ]}
              onChange={dateFilter}
            />,
            <Select
              key="DropDown3"
              mode="multiple"
              style={{ width: "200px" }}
              options={options}
              placeholder="Select issuer..."
              maxTagCount="responsive"
              value={value}
              onChange={(val) => {
                if (
                  filters.startDate === "" ||
                  filters.startDate === null ||
                  filters.endDate === "" ||
                  filters.endDate === null
                ) {
                  message.error("Эхлэх хугацаа, дуусах хугацааг сонгоно уу.");
                  return;
                }
                const lostOne = value.filter((item) => !val.includes(item));
                if (lostOne.length > 0) {
                  if (lostOne[0] === "all") {
                    if (value.length === options.length) {
                      setValue([]);
                      return;
                    }
                  }
                //   const lostOptionIndex = options.findIndex(
                //     (option) => option.value === lostOne[0]
                //   );
                //   options[lostOptionIndex].displayData = null;
                //   options[lostOptionIndex].tablePageData = {
                //     request: {
                //       pageNo: 1,
                //       perPage: 5,
                //       sort: "",
                //     },
                //   };
                //   options[lostOptionIndex].headerIndexes = [];
                //   options[lostOptionIndex].subheaderIndexes = [];
                }

                const addedOne = val.filter((item) => !value.includes(item));
                if (addedOne.length > 0) {
                  if (addedOne[0] === "all") {
                    let list = [];
                    options.forEach((el) => {
                      list.push(el.value);
                    });
                    setValue(list);
                    return;
                  }
                }
                //     let data = options.filter((item) => item.value !== "all");
                //     let unSelectedItems = [];
                //     data.forEach((el) => {
                //       const found = value.some((item) => el.value === item);
                //       if (!found) {
                //         unSelectedItems.push(el);
                //       }
                //     });
                //     // console.log("zz -> ", unSelectedItems);
                //     unSelectedItems.forEach((el) => {
                //       getData(el.value);
                //     });
                //     setValue([...value, ...unSelectedItems.map((el) => el.value)]);
                //     return;
                //   }
                //   // const addedOptionIndex = options.findIndex(
                //   //   (option) => option.value === addedOne[0]
                //   // );
                //   // getData(addedOne[0]);
                // }
                setValue(val);
              }}
              optionRender={(option) => {
                // console.log("options", option);
                return (
                  <Space>
                    <span role="img" aria-label={option.regUserId}>
                      {option.regUserId}
                    </span>
                  </Space>
                );
              }}
            />,
            <Button key="ClearButton4" onClick={onClear}>Цэвэрлэх</Button>,
            <Button
              key="DownloadButton3"
              style={{ backgroundColor: "green", color: "white" }}
              onClick={() => {
                value
                  .filter((opCode) => opCode !== "all")
                    .forEach((opCode) => {
                      const selectedOption = options.find((option) => option.value === opCode);
                      if (selectedOption){
                        fetchReportFile(
                          "/report/net/settlement?xlsx=true",
                          "POST",
                          accessToken,
                          {
                            ...filters,
                            operatorCode: opCode,
                          },
                          `${fileName}_${selectedOption.nameEn}`.toLowerCase()
                        );
                      }
                    });
              }}
            >
              Татах
            </Button>,
          ]}
        ></Filter>

        {/* <React.Fragment key={}> */}
        {/* <h3>{selectedOption.label}</h3> */}
        {data !== null && (
          <Table
            tableLoading={tableLoading}
            filters={filters}
            ref={ref}
            data={data}
            // paricipment={selectedOption.name}
            // setHeaderIndexes={selectedOption.setHeaderIndexes}
            // setSubheaderIndexes={selectedOption.setSubheaderIndexes}
            // perPage={selectedOption.tablePageData.request.perPage}
            // headerIndexes={selectedOption.headerIndexes}
            // subheaderIndexes={selectedOption.subheaderIndexes}
            rowKey={(record) => record?.rowKey}
            getData={getData}
            value={value}
            options={options}
          />
        )}
        {/* <div style={{ height: "30px" }}></div> */}
        {/* </React.Fragment> */}

        {/* {value.map((el, index) => {
          const selectedOption = options.find((option) => option.value === el);
          return (
          );
        })} */}
      </PageContent>
    </PageContainer>
  );
};

const Table = styled(NetSettlementTable)`
  .ant-table-cell {
    padding: 0px;
    line-height: 15px;
  }
  .ant-table-thead .ant-table-cell {
    padding: 8px;
  }
  .ant-table-summary .ant-table-cell {
    padding: 8px;
  }
  .header-cell {
    text-align: left;
    background-color: #f5f5f5;
    font-weight: bold;
  }
  .subheader-cell {
    padding: 8px;
    padding-left: 40px;
    font-weight: bold;
  }
  .subvalue-cell {
    padding: 8px;
    font-weight: bold;
  }
  .cell-center {
    text-align: center;
  }
`;
