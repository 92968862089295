import React, { Children } from "react";
import { Table } from "antd";
import moment from "moment";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";
import formatNumber from "../../utils/format";
import stringToFloat from "../../utils/parser";

const ReconcilationTable = (
  {
    paricipment,
    loadData,
    data,
    pagination = true,
    rowExpandable,
    limit: initialLimit = 100,
    filters,
    items: rows,
    setHeaderIndexes,
    setSubheaderIndexes,
    perPage,
    getData,
    value,
    el,
    headerIndexes,
    subheaderIndexes,
    initialLoading,
    onTableChange = () => {},
    onResult = () => {},
    ...rest
  },
  ref
) => {
  const [loading, setLoading] = React.useState(initialLoading);
  const [items, setItems] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  // const [tableFilter, setTableFilter] = React.useState({});

  const [field, setField] = React.useState();
  const [sort, setSort] = React.useState();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(perPage);
  const [pageCount, setPageCount] = React.useState(1);
  const [totalEl, setTotalEl] = React.useState(1);

  const initialRenderPage = React.useRef(true);
  const initialRenderLimit = React.useRef(true);

  const [tableheaderIdx, setTableheaderIdx] = React.useState(headerIndexes);
  const [tablesubheaderIdx, setTablesubheaderIdx] =
    React.useState(setSubheaderIndexes);

  const reload = React.useCallback(
    async (signal) => {
      if (!loadData) return;
      setLoading(true);

      const res = await loadData(
        {
          filter: {
            query: "",
            ...filters,
          },
          order: {
            [field]: sort,
          },
          offset: {
            page : page,
            limit: limit,
          },
        },
        { signal }
      );
      if (res.type === "error") return console.log("error", res.payload);

      setItems(
        res.rows.map((row, index) => ({
          ...row,
          rowKey: index + 1,
        }))
      );
      setTotal(res.count);

      if (onResult) onResult(res);

      setLoading(false);
    },
    [filters, limit, loadData, field, sort, page]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  React.useImperativeHandle(ref, () => ({
    reload() {
      reload();
    },
    setItems(items) {
      setItems(items);
    },
    getLoading() {
      return loading;
    },
    setLoading(loadStatus) {
      setLoading(loadStatus);
    },
    getPage() {
      return page;
    },
    getLimit() {
      return limit;
    },
  }));

  React.useEffect(() => {
    if (rows) {
      setItems(rows);
      setTotal(rows.length);
    }
  }, [rows]);

  // let allDates = [];
  // let startDate = new Date(filters.startDate);
  // const finalDate = new Date(filters.endDate);

  // while (startDate <= finalDate) {
  //   allDates.push(new Date(startDate));
  //   startDate.setDate(startDate.getDate() + 1);
  // }

  React.useEffect(() => {
    let headerIdx = [];
    let subheaderIdx = [];
    const listData = [];

    console.log("data------------>", data);

    if (data != null) {
      if (data && Array.isArray(data)) {
        listData.push({
          paymentMethod: paricipment,
        });
        headerIdx.push(0);
        let totalOutgoingCount = 0;
        let totalOutgoingAmount = 0;
        let totalIncomingCount = 0;
        let totalIncomingAmount = 0;
        data.forEach((el) => {
          totalOutgoingCount += stringToFloat(el.creditCount.toString());
          totalOutgoingAmount += stringToFloat(el.creditAmount.toString());
          totalIncomingCount += stringToFloat(el.debitCount.toString());
          totalIncomingAmount += stringToFloat(el.debitAmount.toString());
          listData.push(el);
        });
        listData.push({
          paymentMethod: "Нийт гүйлгээ",
          creditCount  : totalOutgoingCount,
          creditAmount : formatNumber(totalOutgoingAmount),
          debitCount   : totalIncomingCount,
          debitAmount  : formatNumber(totalIncomingAmount),
        });
        subheaderIdx.push(listData.length - 1);
        setTableheaderIdx(headerIdx);
        setTablesubheaderIdx(subheaderIdx);
        setItems(listData);

        console.log("headerIdx", headerIdx);
        console.log("subheaderIdx", subheaderIdx);
        console.log("listData", listData);
      }
    } else {
      setTableheaderIdx([]);
      setTablesubheaderIdx([]);
      setItems([]);
    }
  }, [data]);

  // React.useEffect(() => {
  //   // value.forEach((el) => {
  //   //   getData(el);
  //   // });
  //   getData(el);
  // }, [page]);

  // React.useEffect(() => {
  //   getData(el);
  // }, [limit]);

  React.useEffect(() => {
    if (initialRenderPage.current) {
      initialRenderPage.current = false;
    } else {
      getData(el);
    }
  }, [page]);

  React.useEffect(() => {
    if (initialRenderLimit.current) {
      initialRenderLimit.current = false;
    } else {
      getData(el);
    }
  }, [limit]);

  const useHeader = ({ type, onAction, s3host }) => {
    const fixNest = (_, index) => {
      if (tableheaderIdx.includes(index)) {
        return 6;
      }
      if (tablesubheaderIdx.includes(index)) {
        return 2;
      }
      return 1;
    };

    const fixNest2 = (_, index) => {
      if (tableheaderIdx.includes(index) || tablesubheaderIdx.includes(index)) {
        return "header-cell";
      }
      // return "cell-center";
    };

    const onHeaderCell = (_, index) => {
      let idx = fixNest(_, index);
      let classname = fixNest2(_, index);
      return {
        colSpan  : idx,
        className: classname,
      };
    };

    const onHeaderCell2 = (_, index) => {
      let span = 1;
      let cl = "";
      if (tableheaderIdx.includes(index)) {
        span = 0;
      }
      if (tablesubheaderIdx.includes(index)) {
        span = 1;
        cl = "header-cell";
      }
      return {
        colSpan  : span,
        className: cl,
      };
    };

    const onHeaderCell3 = (_, index) => {
      let span = 1;
      let cl = "";
      if (tableheaderIdx.includes(index)) {
        span = 0;
      }
      if (tablesubheaderIdx.includes(index)) {
        span = 0;
        cl = "header-cell";
      }
      return {
        colSpan  : span,
        className: cl,
      };
    };

    return [
      {
        title : "Гүйлгээний төрөл",
        render: (record, index) => {
          return <div style={{ padding: "8px" }}>{record.paymentMethod}</div>;
        },
        onCell: onHeaderCell,
      },
      {
        title : "Төлөв",
        render: (record) => {
          return (
            <div style={{ padding: "8px" }}>{record.transactionStatus}</div>
          );
        },
        onCell: onHeaderCell3,
        // onCell: (_, index) => ({
        //   colSpan:
        //     tableheaderIdx.includes(index) || tablesubheaderIdx.includes(index)
        //       ? 0
        //       : 1,
        // }),
      },
      {
        title   : "Илгээсэн",
        onCell  : onHeaderCell2,
        children: [
          {
            title : "Тоо",
            render: (record) => {
              return <div style={{ padding: "8px" }}>{record.creditCount}</div>;
            },
            onCell: onHeaderCell2,
          },
          {
            title : "Дүн",
            render: (record) => {
              return (
                <div style={{ padding: "8px" }}>{record.creditAmount}</div>
              );
            },
            onCell: onHeaderCell2,
          },
        ],
        // render: (record) => {
        //   return <div style={{ padding: "8px" }}>{record.bankCode}</div>;
        // },
        // onCell: (_, index) => ({
        //   colSpan:
        //     tableheaderIdx.includes(index) || tablesubheaderIdx.includes(index)
        //       ? 0
        //       : 1,
        // }),
      },
      {
        title   : "Хүлээн авсан",
        onCell  : onHeaderCell2,
        children: [
          {
            title : "Тоо",
            render: (record) => {
              return <div style={{ padding: "8px" }}>{record.debitCount}</div>;
            },
            onCell: onHeaderCell2,
          },
          {
            title : "Дүн",
            render: (record) => {
              return <div style={{ padding: "8px" }}>{record.debitAmount}</div>;
            },
            onCell: onHeaderCell2,
          },
        ],
        // onCell: (_, index) => ({
        //   colSpan:
        //     tableheaderIdx.includes(index) || tablesubheaderIdx.includes(index)
        //       ? 0
        //       : 1,
        // }),
      },
    ];
  };

  return (
    <>
      <Table
        {...{
          scroll    : { x: 100 },
          bordered  : true,
          columns   : useHeader({}),
          dataSource: items,
          rowExpandable,
          pagination: false,
          ...rest,
        }}
        loading={loading}
        rowKey={(record, index) => record.id || index}
      />
      {/* <Box
        className="custom-pagination"
        style={{
          marginTop: "0px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          component="div"
          count={totalEl}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          onRowsPerPageChange={(event) => {
            // console.log("limit changed", parseInt(event.target.value, 10));
            setLimit(parseInt(event.target.value, 10));
            setPage(1);
          }}
          sx={{
            "& p": {
              marginTop: "auto",
              marginBottom: "auto",
            },
            ".MuiTablePagination-actions > button:first-of-type": {
              display: "none",
            },
            ".MuiTablePagination-actions > button:last-of-type": {
              display: "none",
            },
            ".MuiTablePagination-displayedRows": {
              display: "none",
            },
          }}
        />
        <Pagination
          count={pageCount}
          page={page}
          onChange={(_, newPage) => {
            setPage(newPage);
          }}
          color="primary"
          shape="rounded"
        />
      </Box> */}
    </>
  );
};

export default React.forwardRef(ReconcilationTable);
