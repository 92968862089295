import React from "react";
import { Tag, Button, DatePicker } from "antd";
import { connection as connectionApi } from "../../apis";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { MyTable, RowAction } from "../../components";
import { Filter } from "../../components/Design";
import moment from "moment";
import styled from "styled-components";

export default () => {
  const myTableRef = React.useRef();

  const [filters, setFilters] = React.useState({
    start_date: "",
    end_date  : ""
  });

  const columns = useHeader({});

  const dateFilter = (e)=> {
    setFilters({ ...filters, start_date: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"), end_date: e &&moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm") });
  };

  const onClear = () => {
    setFilters({
      start_date: undefined,
      end_date  : undefined
    });
  };

  return (
    <PageContainer>
      <PageHeader title="Систем холболт" />
      <PageContent>
        <Filter extra={[
          <DatePicker.RangePicker value={[filters.start_date && moment(filters.start_date), filters.end_date && moment(filters.end_date)]} onChange={dateFilter} />,
          <Button onClick={onClear}>Цэвэрлэх</Button>]} >
        </Filter>

        <Table
          ref={myTableRef}
          columns={columns}
          filters={filters}
          loadData={connectionApi.stats}
          rowKey={(record) => record?.rowKey}/>
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ type, onAction, s3host }) => {
  return [{
    title    : "№",
    dataIndex: "rowKey",
    width    : "20px"
  }, {
    title : "Харилцагч",
    render: (record) => {
      return (
        <div>
          <div style={{ marginBottom: 5 }}>{record.operatorName}</div>
          <Tag>{record.operatorCode}</Tag>
        </div>
      );
    }
  }, {
    title : "Client",
    render: (record) => {
      return (
        <div>
          <div style={{ marginBottom: 5 }}>{record.clientType}</div>
          <Tag>{record.clientId}</Tag>
        </div>
      );
    }
  }, {
    title : "Host",
    render: (record) => {
      return (<div>{record.host}:{record.port}</div>);
    }
  }, {
    title : "Status",
    render: (record) => {
      return record.clientStatus === "CONNECTED" ? <Tag color="green">CONNECTED</Tag> : <Tag color="red">DISCONNECTED</Tag>;
    }
  }, {
    title : "Disconnect",
    render: (record) => {
      return record.disconnected || 0;
    }
  }, {
    title : "Last disconnect date",
    render: (record) => {
      return (<div>{record.lastDisconnectDate ? moment(record.lastDisconnectDate).format("YYYY-MM-DD HH:mm:ss") : "-"}</div>);
    }
  }, {
    title : "Last request date",
    render: (record) => {
      return (<div>{record.lastRequestDate ? moment(record.lastRequestDate).format("YYYY-MM-DD HH:mm:ss") : "-"}</div>);
    }
  }];
};

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;