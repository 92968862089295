import request from "./request";
import moment from "moment";
import numeral from "numeral";

const dateFormat = (date) => moment(date).format("YYYY-MM-DD");
const datetimeFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm");

let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const phoneNumber = /[0-9]/gm;
const mongoliaText = /^[А-Яа-я||Үү||Өө||0-9]+$/;
const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;
const passwordRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]/;
const mongolText = /^[А-Яа-я||Үү||Өө||\-||]+$/;
const latin = /^[A-Za-z||Aa||Zz||_|0-9|| |-]+$/;
const latinNo = /^[A-Za-z||Aa||Zz|||-]+$/;
const websiteRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

numeral.register("locale", "mn", {
  delimiters: {
    thousands: ",",
    decimal  : "."
  },
  abbreviations: {
    thousand: "k",
    million : "m",
    billion : "b",
    trillion: "t"
  },
  currency: {
    symbol: "₮"
  }
});

numeral.locale("mn");

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  style: {
    marginTop: 20
  },
  wrapperCol: {
    xs: {
      span  : 24,
      offset: 0,
    },
    sm: {
      span  : 16,
      offset: 8,
    },
  },
};
const tugrug = val => numeral(parseFloat(val)).format("0,0.00$");

export { tugrug, request, dateFormat, datetimeFormat, formItemLayout, tailFormItemLayout, mailformat, phoneNumber, mongoliaText, lowercaseRegex, uppercaseRegex, numericRegex, passwordRegex, mongolText, latin, latinNo, websiteRegex };
